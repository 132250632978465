import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['display']

  connect() {
    super.connect();

    this.displayTarget.innerText = "Hello world!"
  }
}