import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    finalUrl: String,
    productName: String,
    productId: String,
    price: Number,
    currency: String,
    transactionId: String
  }

  connect() {
    this.redirectFallback()
    this.checkTrackingReady()
  }

  // We need to make sure that GTM is loaded and ready to receive the conversion event.
  // Main reason for an issue here would be network speed (not super likely), or
  // ad blockers (quite likely for a decent chunk of people).
  checkTrackingReady() {
    window.dataLayer = window.dataLayer || [];

    const gtmReady = window.dataLayer.some(function(event) {
      return event.event === 'gtm.js';
    });

    if (gtmReady && typeof gtag === 'function') {
      this.sendConversionEvent();
    } else {
      setTimeout(() => this.checkTrackingReady(), 500); // Retry after 500ms
    }
  }

  sendConversionEvent() {
    try {
      gtag("event", "purchase", {
        transaction_id: this.transactionIdValue,
        value: this.priceValue,
        currency: this.currencyValue.toUpperCase(),
        items: [
         {
          item_id: this.productIdValue,
          item_name: this.productNameValue,
          index: 0,
          price: this.priceValue,
          quantity: 1
        }]
    });
    } catch (e) {
      // Sending the custom event failed, but we let the user continue is signup from.
      this.redirect();
    }
  }

  // Tracking a conversion is important, but not as important as a user being able to continue
  // their journey, so we want to make sure they get reidrected if it's too long.
  redirectFallback() {
    setTimeout(() => {
      this.redirect()
    }, 3000);
  }

  redirect() {
    window.location = this.finalUrlValue
  }

  disconnect() {
    this.redirect()
  }
}