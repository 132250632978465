import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [ "form" ]
  static values = {
    stripeKey: String
  }

  connect() {
    this.stripe = Stripe(this.stripeKeyValue);
  }

  async renderForm(fetchClientSecret) {
    if (this.checkout) {
      this.checkout.destroy();
    }

    this.checkout = await this.stripe.initEmbeddedCheckout({
      fetchClientSecret
    })

    this.checkout.mount(this.formTarget);
  }
}