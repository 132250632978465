import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static outlets = [ "checkout--stripe-form" ]
  static targets = [ "product" ]
  static values = {
    checkoutSessionClientSecret: String
  }

  connect() {
    if (this.checkoutSessionClientSecretValue !== "") {
      this.checkoutStripeFormOutlet.renderForm(async () => this.checkoutSessionClientSecretValue)
      return
    }

    if (this.productTargets.length === 1) {
      this.selectProduct(this.productTarget);
    }
  }

  select(event) {
    this.selectProduct(event.currentTarget);
  }

  async selectProduct(element) {
    const priceId = element.dataset.priceId;
    const priceName = element.dataset.priceName;
    const productName = element.dataset.productName;

    if (mixpanel) {
      mixpanel.track("Selected plan", {
        productName, priceName
      });
    }

    const clientSecret = await this.fetchClientSecret(priceId)

    this.checkoutStripeFormOutlet.renderForm(
      this.fetchClientSecret.bind(this, priceId)
    )
  }

  async fetchClientSecret(priceId) {
    const response = await this.fetch(
      `/checkouts?price_id=${priceId}`,
      { method: "POST" }
    )

    const { clientSecret } = await response.json()

    return clientSecret
  }
}