import "@enginuitygroup/library";
import "@fortawesome/fontawesome-free/js/all"
import "popper.js/dist/popper.min.js"
import "bootstrap"
import "../accounts/index.sass"
import "../accounts/tailwind.scss"

import App from "../accounts/app.js"

import.meta.glob([
  "../../components/**/*.sass",
  "../../components/**/*.js"
], { eager: true })

import "../accounts/controllers"

import PhotoPreviewer from "../accounts/PhotoPreviewer"
PhotoPreviewer.start()

const images = import.meta.glob("../images", { eager: true })

// Replace `DOMContentLoaded` with `turbo:load` if'n we
// ever get to using turbo.
document.addEventListener("DOMContentLoaded", function() {
  window.app = new App()
})

import * as Turbo from "@hotwired/turbo"
Turbo.start()
Turbo.session.drive = false
//
// import ActiveStorage from "@rails/activestorage"
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.glob("./**/*_channel.js", { eager: true })
